@import '~react-redux-toastr/src/styles/index.scss';

$toastr-width: 450px;

.redux-toastr {
  > span > div {
    width: auto !important;
    max-width: $toastr-width !important;
  }
  
  .toastr {
    padding: 1rem .5rem;
    border-radius: 0 !important;
    font-size: .8rem;
    
    &.toastr-download {
      padding: 0;
      min-height: 45px;
      height: 45px;
      
      > div {
        height: 45px;
      }
      
      .rrt-left-container {
        .rrt-holder {
          display: flex;
        }
      }

      .rrt-middle-container {
        .rrt-title {
          margin-bottom: 0 !important;
        }
      }
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: center;

      .rrt-left-container,
      .rrt-middle-container,
      .rrt-right-container {
        width: auto !important;
        padding: 0 !important;
        margin: 0 .75rem !important;
        position: relative !important;
        float: none !important;
        overflow: visible !important;

        > * {
          width: auto !important;
          height: auto !important;
          padding: 0 !important;
          margin: 0 !important;
          position: relative !important;
        }
        
        .rrt-title {
          margin-bottom: .5rem !important;
        }
      }

      .close-toastr {
        margin: 0 1rem;
        display: inline-block;
      }
    }

    &.rrt-success {
      background-color: $white !important;
      color: $black !important;
      border-left: 3px solid $green;

      .toastr-icon {
        fill: $green !important;
      }
    }

    &.rrt-error {
      background-color: $white !important;
      color: $black !important;
      border-left: 3px solid $red;

      .toastr-icon {
        fill: $red !important;
      }
    }

    &.rrt-warning {
      background-color: $white !important;
      color: $black !important;
      border-left: 3px solid $yellow;

      .toastr-icon {
        fill: $yellow !important;
      }
    }
  }
}